<template>
	<section class="overflow-hidden bg-gradient-to-b from-[#1B1B1B]/25 to-rich-black">
		<div class="container-area pt-[2.5625rem] pb-8 lg:py-20">
			<h2 class="mb-12 text-center text-3xl font-bold leading-6 px-6 lg:px-0">CEO da VIP Promotora de Crédito, uma das maiores do Brasil</h2>
			<ul class="relative flex flex-wrap items-center justify-between gap-6 px-6 lg:gap-0 lg:px-0" id="list">
				<li v-for="(bullet, index) in bullets" :key="index" class="flex h-[13rem] flex-col justify-center rounded-lg px-8 transition-all ease-in-out" :class="bullet.li_class">
					<span class="mt-8 mb-2" v-html="bullet.icon"></span>
					<h3 class="whitespace-nowrap font-inter text-3xl font-bold leading-8" :class="bullet.txt_class" v-html="bullet.title"></h3>
					<p class="mt-auto mb-4 font-light leading-3" :class="bullet.txt_class" v-html="bullet.text"></p>
				</li>
			</ul>
		</div>
	</section>
</template>
<script>
export default {
	name: "StripeSection",
	data() {
		return {
			breakpoint: 0,
			bullets: [
				{
					icon: '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.0013 4C3.2653 4 2.66797 4.59733 2.66797 5.33333V18.6667C2.66797 19.4027 3.2653 20 4.0013 20H28.0013C28.7373 20 29.3346 19.4027 29.3346 18.6667V5.33333C29.3346 4.59733 28.7373 4 28.0013 4H4.0013ZM6.55078 6.66667H25.4518C25.5513 6.94707 25.7121 7.20175 25.9225 7.41214C26.1329 7.62253 26.3876 7.78333 26.668 7.88281V16.1172C26.3876 16.2167 26.1329 16.3775 25.9225 16.5879C25.7121 16.7982 25.5513 17.0529 25.4518 17.3333H6.55078C6.45129 17.0529 6.2905 16.7982 6.08011 16.5879C5.86972 16.3775 5.61504 16.2167 5.33464 16.1172V7.88281C5.61504 7.78333 5.86972 7.62253 6.08011 7.41214C6.2905 7.20175 6.45129 6.94707 6.55078 6.66667ZM16.0013 8C14.9404 8 13.923 8.42143 13.1729 9.17157C12.4227 9.92172 12.0013 10.9391 12.0013 12C12.0013 13.0609 12.4227 14.0783 13.1729 14.8284C13.923 15.5786 14.9404 16 16.0013 16C17.0622 16 18.0796 15.5786 18.8297 14.8284C19.5799 14.0783 20.0013 13.0609 20.0013 12C20.0013 10.9391 19.5799 9.92172 18.8297 9.17157C18.0796 8.42143 17.0622 8 16.0013 8ZM9.33463 10.6667C8.59864 10.6667 8.0013 11.2627 8.0013 12C8.0013 12.7373 8.59864 13.3333 9.33463 13.3333C10.0706 13.3333 10.668 12.7373 10.668 12C10.668 11.2627 10.0706 10.6667 9.33463 10.6667ZM22.668 10.6667C21.932 10.6667 21.3346 11.2627 21.3346 12C21.3346 12.7373 21.932 13.3333 22.668 13.3333C23.404 13.3333 24.0013 12.7373 24.0013 12C24.0013 11.2627 23.404 10.6667 22.668 10.6667ZM5.32161 22.6667L25.6393 27.9583C26.3527 28.1437 27.079 27.7146 27.2643 27.0026L28.3945 22.6667H25.6393L25.3242 23.8672C24.7255 23.9192 24.185 24.2384 23.8477 24.7344L15.9049 22.6667H5.32161Z" fill="#09090A"/></svg>',
					title: "16 anos",
					text: "No ramo de <br /> consignado INSS",
					li_class: "bg-sean-green flex-1 lg:flex-[0_1_15.5rem] lg:hover:flex-[0_1_28.0625rem]",
					txt_class: "text-rich-black"
				},
				{
					icon: '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.9362 1.33325L10.6029 6.66658H14.668V9.33325H17.3346V6.66658H21.2695L15.9362 1.33325ZM4.0013 11.9999C3.2653 11.9999 2.66797 12.5973 2.66797 13.3333V26.6666C2.66797 27.4026 3.2653 27.9999 4.0013 27.9999H28.0013C28.7373 27.9999 29.3346 27.4026 29.3346 26.6666V13.3333C29.3346 12.5973 28.7373 11.9999 28.0013 11.9999H4.0013ZM6.55078 14.6666H25.4518C25.5513 14.947 25.7121 15.2017 25.9225 15.4121C26.1329 15.6224 26.3876 15.7832 26.668 15.8827V24.1171C26.3876 24.2166 26.1329 24.3774 25.9225 24.5878C25.7121 24.7982 25.5513 25.0528 25.4518 25.3333H6.55078C6.45129 25.0528 6.2905 24.7982 6.08011 24.5878C5.86972 24.3774 5.61504 24.2166 5.33464 24.1171V15.8827C5.61504 15.7832 5.86972 15.6224 6.08011 15.4121C6.2905 15.2017 6.45129 14.947 6.55078 14.6666ZM16.0013 15.9999C14.9404 15.9999 13.923 16.4213 13.1729 17.1715C12.4227 17.9216 12.0013 18.9391 12.0013 19.9999C12.0013 21.0608 12.4227 22.0782 13.1729 22.8283C13.923 23.5785 14.9404 23.9999 16.0013 23.9999C17.0622 23.9999 18.0796 23.5785 18.8297 22.8283C19.5799 22.0782 20.0013 21.0608 20.0013 19.9999C20.0013 18.9391 19.5799 17.9216 18.8297 17.1715C18.0796 16.4213 17.0622 15.9999 16.0013 15.9999ZM9.33464 18.6666C8.59864 18.6666 8.0013 19.2626 8.0013 19.9999C8.0013 20.7373 8.59864 21.3333 9.33464 21.3333C10.0706 21.3333 10.668 20.7373 10.668 19.9999C10.668 19.2626 10.0706 18.6666 9.33464 18.6666ZM22.668 18.6666C21.932 18.6666 21.3346 19.2626 21.3346 19.9999C21.3346 20.7373 21.932 21.3333 22.668 21.3333C23.404 21.3333 24.0013 20.7373 24.0013 19.9999C24.0013 19.2626 23.404 18.6666 22.668 18.6666Z" fill="#E4E8F2"/></svg>',
					title: "+ de 3 bi",
					text: "Emprestados",
					li_class: "flex-1 lg:flex-[0_1_15.5rem] lg:hover:flex-[0_1_28.0625rem] bg-sean-green/10",
					txt_class: "text-white"
				},
				{
					icon: '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.0013 2.66675C11.2945 2.66675 7.16811 5.11189 4.79557 8.79435L2.66797 6.66675V13.3334H9.33464L6.74089 10.7397C8.57462 7.51496 12.0271 5.33341 16.0013 5.33341C21.892 5.33341 26.668 10.1094 26.668 16.0001C26.668 21.8907 21.892 26.6667 16.0013 26.6667C15.2066 26.6667 14.4164 26.5797 13.6497 26.4063L13.0638 29.0079C14.0225 29.2239 15.0106 29.3334 16.0013 29.3334C23.3653 29.3334 29.3346 23.3641 29.3346 16.0001C29.3346 8.63608 23.3653 2.66675 16.0013 2.66675ZM15.6185 8.00008V9.43758C15.1665 9.49491 12.6367 9.97019 12.6367 13.0235C12.6367 17.4782 17.2487 16.1609 17.2487 19.1329C17.2487 20.6209 16.2858 20.6563 16.0898 20.6563C15.9112 20.6563 14.7383 20.7514 14.7383 18.474H12.2174C12.2174 22.33 15.015 22.6355 15.431 22.6928V24.0001H16.7669V22.6928C17.2176 22.6368 19.7669 22.2187 19.7669 19.1147C19.7669 14.84 15.1563 15.6579 15.1549 13.0365C15.1549 11.5339 15.9815 11.4871 16.1602 11.4871C16.4868 11.4871 17.2643 11.7736 17.2643 13.6042H19.7852C19.7852 10.1016 17.3902 9.55537 16.9622 9.47404V8.00008H15.6185ZM2.66797 16.0001L2.67057 16.211C2.68524 17.2004 2.8097 18.186 3.04036 19.1407L5.63411 18.5183C5.44878 17.7543 5.34924 16.9627 5.33724 16.1694L5.33464 16.0001H2.66797ZM6.4388 20.7371L4.05078 21.9246C4.49078 22.8059 5.02834 23.6436 5.65234 24.4089L7.72005 22.7266C7.22139 22.1133 6.7908 21.4437 6.4388 20.7371ZM9.40755 24.3855L7.75651 26.4792C8.53384 27.0912 9.37759 27.6177 10.2669 28.0417L11.4154 25.6355C10.7034 25.2955 10.0289 24.8748 9.40755 24.3855Z" fill="#09090A"/></svg>',
					title: "+10.000",
					text: "Clientes por mês",
					li_class: "flex-1 lg:flex-[0_1_15.5rem] lg:hover:flex-[0_1_28.0625rem] bg-white",
					txt_class: "text-rich-black"
				},
				{
					icon: '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2.66669C7.29276 2.66669 6.61448 2.94764 6.11438 3.44774C5.61428 3.94783 5.33333 4.62611 5.33333 5.33335C5.33333 6.0406 5.61428 6.71887 6.11438 7.21897C6.61448 7.71907 7.29276 8.00002 8 8.00002C8.70724 8.00002 9.38552 7.71907 9.88562 7.21897C10.3857 6.71887 10.6667 6.0406 10.6667 5.33335C10.6667 4.62611 10.3857 3.94783 9.88562 3.44774C9.38552 2.94764 8.70724 2.66669 8 2.66669ZM24 2.66669C23.2928 2.66669 22.6145 2.94764 22.1144 3.44774C21.6143 3.94783 21.3333 4.62611 21.3333 5.33335C21.3333 6.0406 21.6143 6.71887 22.1144 7.21897C22.6145 7.71907 23.2928 8.00002 24 8.00002C24.7072 8.00002 25.3855 7.71907 25.8856 7.21897C26.3857 6.71887 26.6667 6.0406 26.6667 5.33335C26.6667 4.62611 26.3857 3.94783 25.8856 3.44774C25.3855 2.94764 24.7072 2.66669 24 2.66669ZM6.66667 10.6667C5.19333 10.6667 4 11.86 4 13.3334V21.3334V29.3334H6.66667V21.3334H9.33333V29.3334H12V20V16.2292L16 20.2292L20 16.2292V21.3334V29.3334H22.6667V21.3334H25.3333V29.3334H28V20V13.3334C28 11.86 26.8067 10.6667 25.3333 10.6667H22.6667C21.9253 10.6667 21.2566 10.9704 20.7734 11.4584L20.7708 11.4557L16 16.2292L11.2188 11.4479L11.1849 11.4115L11.1823 11.4167C10.7027 10.9541 10.0527 10.6667 9.33333 10.6667H6.66667ZM6.66667 13.3334H9.33333V18.6667H6.66667V13.3334ZM22.6667 13.3334H25.3333V18.6667H22.6667V13.3334Z" fill="#00FFB9"/></svg>',
					title: "+200",
					text: "Colaboradores",
					li_class: "flex-1 lg:flex-[0_1_15.0625rem] lg:hover:flex-[0_1_28.0625rem] bg-eerie-black",
					txt_class: "text-sean-green"
				}
			]
		}
	},
	methods: {
		resizeHandler() {
			this.breakpoint = document.body.offsetWidth
		},
		listAnimate() {
			if (this.breakpoint < 1025) return

			document.querySelector("#list li:last-child").classList.add("no-active")

			document.querySelector("#list").addEventListener("mouseenter", () => {
				document.querySelector("#list li:last-child").classList.remove("no-active")
			})

			document.querySelector("#list").addEventListener("mouseleave", () => {
				document.querySelector("#list li:last-child").classList.add("no-active")
			})
		}
	},
	mounted() {
		this.resizeHandler()
		window.addEventListener("resize", this.resizeHandler)
		this.listAnimate()
	}
}
</script>

<style scoped>
#list:before {
	position: absolute;
	content: "";
	top: 50%;
	width: 50%;
	left: -50%;
	border-bottom: 1px solid #00ffb9;
}
#list:after {
	position: absolute;
	content: "";
	top: 50%;
	width: 50%;
	right: -50%;
	border-bottom: 1px solid #18181b;
}

li.no-active {
	flex: 0 1 28.0625rem !important;
}
</style>
