<template>
	<header class="border-b border-b-spanish-gray/10 md:px-6 px-0 relative">
		<div class="container-area">
			<div class="flex py-10 md:gap-[4.7188rem] px-6 lg:px-0 items-center">
				<Link class="min-w-fit" to="/">
					<template #prefix>
						<div>
							<img src="@/assets/logo.png" alt="Private Consig" class="min-w-max block" />
						</div>
					</template>
				</Link>
				<ul class="flex gap-10 w-full items-center ml-auto">
					<li v-for="(menu, index) in computedMenus" :key="index" class="last:ml-auto" :class="[menu.external ? 'flex' : 'hidden lg:flex']">
						<Link :label="menu.name" :path="menu.path" :external="menu.external" class="leading-1 font-bold flex capitalize" :class="[menu.external ? 'underline text-sean-green gap-3' : 'text-white']">
							<template #prefix>
								<div class="relative top-[2px]" v-html="menu?.icon"></div>
							</template>
						</Link>
					</li>
				</ul>
				<button class="min-w-[32px] min-h-[32px] justify-center mt-4 grid lg:hidden ml-8 top-[3px] relative" @click="toggleMobile">
					<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V3.04757H24V0H0ZM0 6.48389V9.53145H24V6.48389H0ZM0 16.0153V12.9678H24V16.0153H0Z" fill="white"/>
					</svg>
				</button>
			</div>
		</div>
		<div v-if="mobile" class="grid gap-9 px-6 py-14 lg:hidden absolute z-40 bottom-0 translate-y-full w-full h-[calc(100vh-112px)] backdrop-blur-lg bg-eerie-black/70">
			<ul class="grid w-full items-center gap-9 transition-all ease-linear">
				<li v-for="(menu, index) in menus" :key="index" class="text-center">
					<Link :label="menu.name" :path="menu.path" class="text-xl font-semibold leading-[1.9375rem] transition-all ease-linear hover:text-primary" @click="toggleMobile"  />
				</li>
			</ul>
		</div>
	</header>
</template>
<script>
import Link from '@/components/Link.vue'

export default {
	name: 'THeader',
	props: {
		menus: {
			type: Array,
			requred: true
		}
	},
	components: {
		Link,
	},
	data() {
		return {
			mobile: false,
		}
	},
	computed: {
		computedMenus() {
			return  document.body.offsetWidth < 768 ? this.menus.slice(-1) : this.menus
		}
	},
	methods: {
		toggleMobile() {
			this.mobile = !this.mobile

			if( this.mobile ) {
				document.querySelector('html').setAttribute('style', 'overflow: hidden')
			} else document.querySelector('html').removeAttribute('style')
		}
	},
}
</script>