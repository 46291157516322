<template>
	<p v-if="!external" @click="scrollTo(path)" class="cursor-pointer hover:text-sean-green transition-all ease-in-out relative" :class="animation.hover && 'group'">
		<slot name="prefix" />
		<span v-html="label"></span>	
		<slot name="suffix" />
		<lottie-player :id="`player-${animation.id}`" v-if="animation.hasAnimation" class="absolute pointer-events-none" :class="animation.animation_css" :src="animation.src"  background="transparent" :loop="animation.loop" :autoplay="animation.autoplay" speed="1"></lottie-player>
	</p>
	<a v-else :href="path" target="_blank" title="label">
		<slot name="prefix" />
		<span v-html="label"></span>	
		<slot name="suffix" />
		<lottie-player :id="`player-${animation.id}`" v-if="animation.hasAnimation" class="absolute pointer-events-none" :class="animation.animation_css" :src="animation.src"  background="transparent"  speed="1" :loop="animation.loop" :autoplay="animation.autoplay"></lottie-player>
	</a>
</template>
<script>
export default {
	name: 'TLink',
	props: {
		label: {
			type: String,
			required: true,
		},
		path: {
			type: String,
			required: true,
		},
		animation: {
			type: Object,
			default: () => {
				return {
					hasAnimation: false,
					hover: false,
					animation_css: 'top-0 left-0 w-full h-[300px]',
					src: 'https://assets6.lottiefiles.com/packages/lf20_wwhhicx3.json',
					id: '',
					loop: false,
					autoplay: false,
				}
			},
		},
		external: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		scrollTo(id) {
			console.log(id)
			const el = document.getElementById(id)
			console.log(el)
			const top = ((el.getBoundingClientRect().top) + window.scrollY) 
			window.scrollTo({
				top, 
				left: 0,
				behavior: 'smooth',
			})
		}
	},
}
</script>