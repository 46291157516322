<template>
	<section id="inicio" ref="inicio" class="grid">
		<div class="container-area relative z-20 order-2 lg:order-1">
			<div class="relative top-0 -mt-[40%]  grid w-full translate-y-0 items-center lg:absolute lg:top-1/2 lg:mt-0 lg:translate-y-1/2">
				<div class="flex w-full flex-col justify-between lg:flex-row lg:items-start">
					<div class="ml-0 lg:ml-[103px]">							
						<img src="@/assets/logo-hero.png" alt="Private Consig" class="mx-auto mb-[4.375rem] lg:mx-0 max-w-[90%] md:max-w-none" id="hero-logo" />
						<h1 class="mb-[1.6875rem] min-w-fit max-w-[20.9375rem] text-center md:text-5xl text-3xl font-bold leading-8 lg:w-auto lg:max-w-none lg:text-left">
							<span class="highlight">Transforme</span> o
							<br class="hidden lg:inline-block" />
							faturamento da <br class="hidden lg:inline-block" />
							sua promotora <br class="hidden lg:inline-block" />
							de crédito
						</h1>
						<Link label="saiba mais" class="mx-auto mb-8 mt-[0.875rem] flex w-fit items-center gap-4 font-bold uppercase leading-1 text-sean-green lg:mx-0 lg:mb-0 lg:mt-0" ref="anchor" path="spotlight">
							<template #prefix>
								<div>
									<svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M8.76117 7.10608L5.87612 10.1459V0.914795H4.12388V10.1459L1.23883 7.10608L0 8.41135L3.76117 12.3742C4.08976 12.7204 4.53537 12.9148 5 12.9148C5.46463 12.9148 5.91024 12.7204 6.23883 12.3742L10 8.41135L8.76117 7.10608Z" fill="#00FFB9" />
									</svg>
								</div>
							</template>
						</Link>
					</div>
					<div class="mr-[1.25rem] hidden gap-[1.6875rem] pt-[3.8125rem] lg:grid">
						<svg width="94" height="98" viewBox="0 0 94 98" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M5.75772 0.333252H0.0175781V6.07339H5.75772V0.333252ZM5.75772 61.3223H0.0175781V67.0624H5.75772V61.3223ZM0.0175781 30.469H5.75772V36.2092H0.0175781V30.469ZM5.75772 91.458H0.0175781V97.1982H5.75772V91.458ZM35.1752 0.333252H40.9154V6.07339H35.1752V0.333252ZM40.9154 61.3223H35.1752V67.0624H40.9154V61.3223ZM35.1752 30.469H40.9154V36.2092H35.1752V30.469ZM40.9154 91.458H35.1752V97.1982H40.9154V91.458ZM69.6175 0.333252H75.3577V6.07339H69.6175V0.333252ZM75.3577 61.3223H69.6175V67.0624H75.3577V61.3223ZM69.6175 30.469H75.3577V36.2092H69.6175V30.469ZM75.3577 91.458H69.6175V97.1982H75.3577V91.458ZM17.2377 0.333252H22.9779V6.07339H17.2377V0.333252ZM22.9779 61.3223H17.2377V67.0624H22.9779V61.3223ZM17.2377 30.469H22.9779V36.2092H17.2377V30.469ZM22.9779 91.458H17.2377V97.1982H22.9779V91.458ZM52.3954 0.333252H58.1355V6.07339H52.3954V0.333252ZM58.1355 61.3223H52.3954V67.0624H58.1355V61.3223ZM52.3954 30.469H58.1355V36.2092H52.3954V30.469ZM58.1355 91.458H52.3954V97.1982H58.1355V91.458ZM87.555 0.333252H93.2952V6.07339H87.555V0.333252ZM93.2952 61.3223H87.555V67.0624H93.2952V61.3223ZM87.555 30.469H93.2952V36.2092H87.555V30.469ZM93.2952 91.458H87.555V97.1982H93.2952V91.458Z" fill="url(#paint0_linear_246_43)" />
							<defs>
								<linearGradient id="paint0_linear_246_43" x1="46.6564" y1="0.333252" x2="46.6564" y2="97.1982" gradientUnits="userSpaceOnUse">
									<stop stop-color="#00FFB9" />
									<stop offset="1" stop-color="#00FFB9" stop-opacity="0" />
								</linearGradient>
							</defs>
						</svg>
						<h3 class="text-lg font-bold uppercase">alexandre matos</h3>
						<p class="font-light leading-3" id="hero-text">
							Expert em gestão, liderança, inovação e vendas no <br />
							ramo de consignado INSS, com 16 anos de <br />
							experiência e mais de
							<span class="font-bold">3 bi emprestados</span>.
						</p>
						<Link label="Sobre" path="quem-sou-eu" class="flex h-14 w-[17.5rem] items-center justify-center gap-3 rounded bg-sean-green font-bold text-rich-black will-change-transform hover:bg-[#02DA9F] hover:text-rich-black" ref="about">
							<template #prefix>
								<div class="relative top-[1.5px]">
									<svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M15 0.5H1.66667C0.75 0.5 0.00833333 1.25 0.00833333 2.16667L0 17.1667L3.33333 13.8333H15C15.9167 13.8333 16.6667 13.0833 16.6667 12.1667V2.16667C16.6667 1.25 15.9167 0.5 15 0.5ZM9.16667 12.1667H7.5V10.5H9.16667V12.1667ZM9.16667 8.83333H7.5C7.5 8.02083 9.16667 6.37833 9.16667 5.5C9.16667 5.49167 9.1575 4.66667 8.33333 4.66667C7.645 4.66667 7.5 5.3625 7.5 5.5H5.83333C5.83333 4.49167 6.62833 3 8.33333 3C9.89833 3 10.8333 4.27083 10.8333 5.5C10.8333 6.685 9.16667 8.01167 9.16667 8.83333Z" fill="black" />
									</svg>
								</div>
							</template>
						</Link>
					</div>
				</div>
			</div>
		</div>
		<picture class="order-1 lg:order-2">
			<source srcset="@/assets/hero-mobile.png" media="(max-width: 768px)" />
			<img src="@/assets/hero-banner.png" alt="Figure-01" class=" md:h-[896px] w-full object-contain will-change-transform" id="hero-background" />
		</picture>
	</section>
</template>
<script>
import Link from "@/components/Link.vue"

import { gsap } from "gsap"

export default {
	name: "StartSection",
	components: {
		Link
	},
	data() {
		return {
			animation: {
				hasAnimation: true,
				hover: true,
				animation_css: "-top-[125px] left-0 w-full h-[300px] invisible group-hover:visible",
				src: "https://assets4.lottiefiles.com/datafiles/TtpruaCfUbd0Nk2/data.json",
				id: "sobre",
				autoplay: true,
				loop: true
			}
		}
	},
	mounted() {
		const tl = gsap.timeline()

		tl.fromTo(this.$refs.inicio.querySelector("#hero-background"),{
				opacity: 0,
				y: 100
			},{
				opacity: 1,
				y: 0,
				duration: 2
			})
		.fromTo(this.$refs.inicio.querySelector("#hero-logo"), {
				opacity: 0,
				scale: 2
			},{
				opacity: 1,
				scale: 1,
				duration: 0.5
			},"<1")
		.fromTo(this.$refs.inicio.querySelector("h1"), {
				opacity: 0,
				y: 100
			},{
				opacity: 1,
				y: 0,
				duration: 0.75
			},"<1")
		.fromTo(this.$refs.anchor.$el,{
				opacity: 0,
				y: 50
			}, {
				opacity: 1,
				y: 0,
				duration: 0.75
			}, "<")
		.fromTo( this.$refs.inicio.querySelector("h3"), {
				opacity: 0,
				y: 50
			}, {
				opacity: 1,
				y: 0,
				duration: 0.75
			},"<")
		.fromTo(this.$refs.inicio.querySelector("#hero-text"), {
				opacity: 0,
				y: 50
			},{
				opacity: 1,
				y: 0,
				duration: 0.75
			},"<")
		.fromTo(this.$refs.about.$el, {
				opacity: 0,
				y: 50
			},{
				opacity: 1,
				y: 0,
				duration: 0.75
			},"<")
	}
}
</script>
<style scoped>
span.highlight {
	position: relative;
	color: #09090a;
}
span.highlight:after {
	content: "Transforme";
	position: absolute;
	width: 100%;
	height: 90%;
	left: 0;
	bottom: 0;
	background-color: #00ffb9;
}
</style>
