<template>
	<section class="container-area">
		<div class="md:ml-[6.5rem] ml-0 md:mb-[4.8125rem] block px-6 lg:px-0">
			<h3 class="mb-2 flex items-center gap-2 font-bold uppercase text-sean-green">
				<hr class="w-6 border-b border-b-sean-green" />
				depoimentos
			</h3>
			<h2 class="mb-8 text-3xl font-bold leading-6 whitespace-nowrap">Quem já comprou</h2>
		</div>
		<ul class="grid items-stretch justify-between" ref="carrousel">
			<li v-for="(item, index) in testimonials" :key="index" class="relative my-10 mx-4 grid md:max-h-[18.875rem] rounded bg-eerie-black py-4 px-8">
				<img :src="item.picture" :alt="item.name" class="absolute left-8 top-0 -translate-y-1/3" />
				<div class="ml-auto">
					<svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g opacity="0.2">
							<path d="M0.266036 14.3818C2.08798 13.5461 3.5149 12.5736 4.54679 11.4644C5.57869 10.3552 6.17525 9.01045 6.33649 7.4302H4.98212C3.98247 7.4302 3.16824 7.32384 2.53943 7.11111C1.91062 6.89839 1.40273 6.60209 1.01577 6.22222C0.644935 5.87274 0.378899 5.47768 0.217666 5.03704C0.0725552 4.5812 0 4.11776 0 3.64672C0 2.67426 0.411146 1.82336 1.23344 1.09402C2.07185 0.364672 3.05538 0 4.18402 0C5.95759 0 7.32001 0.554606 8.27129 1.66382C9.22257 2.77303 9.69821 4.30769 9.69821 6.26781C9.69821 8.09117 8.87592 9.92972 7.23134 11.7835C5.60287 13.6372 3.63582 15.0427 1.33018 16L0.266036 14.3818Z" fill="#9B9DA4" />
							<path d="M13.5678 14.3818C15.3898 13.5461 16.8167 12.5736 17.8486 11.4644C18.8805 10.3552 19.477 9.01045 19.6383 7.4302H18.2839C17.2843 7.4302 16.47 7.32384 15.8412 7.11111C15.2124 6.89839 14.7045 6.60209 14.3176 6.22222C13.9467 5.87274 13.6807 5.47768 13.5195 5.03704C13.3743 4.5812 13.3018 4.11776 13.3018 3.64672C13.3018 2.67426 13.7129 1.82336 14.5352 1.09402C15.3736 0.364672 16.3572 0 17.4858 0C19.2594 0 20.6218 0.554606 21.5731 1.66382C22.5244 2.77303 23 4.30769 23 6.26781C23 8.09117 22.1777 9.92972 20.5331 11.7835C18.9047 13.6372 16.9376 15.0427 14.632 16L13.5678 14.3818Z" fill="#9B9DA4" />
						</g>
					</svg>
				</div>
				<p class="mb-[2.4375rem] mt-[1.9375rem] min-h-[10.5rem] md:min-h-[7.5rem] text-sm font-light leading-2" v-html="item.text"></p>
				<h5 class="mt-0 text-sm font-bold leading-2 text-sean-green align-bottom" v-text="item.name"></h5>
				<h3 class="pb-2 text-sm leading-2 font-light text-white" v-text="item.author"></h3>
			</li>
		</ul>
	</section>
</template>
<script>
export default {
	name: "TestimonialsSection",
	data() {
		return {
			testimonials: [
				{
					picture: require("@/assets/user-01.png"),
					text: "Foi sensacional! Falamos muito de processos, <br class='hidden md:block-inline' /> sobre a importância da tecnologia dentro da <br class='hidden md:block-inline' /> nossa operação, coisas que nós implantamos e já <br class='hidden md:block-inline' /> vem tendo resultado incrível.",
					name: "Roger Chagas",
					author: 'Grupo Digital',
				},
				{
					picture: require("@/assets/user-02.png"),
					text: "Foi uma experiência incrível, O Alexandre é um <br class='hidden md:block-inline' /> grande líder e empresário. Pude conhecer um <br class='hidden md:block-inline' />  pouco do modelo de negocio de sucesso. Foi  <br class='hidden md:block-inline' /> muito importante a troca de experiência, ouvir <br class='hidden md:block-inline' /> de fora, as dicas e os starts de tanta coisa boa.",
					name: "Juliana Franca",
					author: 'Cidade Azul',
				},
				{
					picture: require("@/assets/user-03.png"),
					text: "O Alexandre é um cara diferenciado, que tem <br class='hidden md:block-inline' />  muito conteúdo bacana para compartilhar e <br class='hidden md:block-inline' />  conhece de perto todas as dores do mercado do <br class='hidden md:block-inline' />  correspondente bancário. A mentoria foi muito <br class='hidden md:block-inline' />  importante e aprendemos muita coisa!",
					name: "Lúcio Marques",
					author: 'Fábrica do Crédito',
				}
			]
		}
	},
	mounted() {
		// eslint-disable-next-line no-undef
		$(this.$refs.carrousel).slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			arrows: false,
			dots: false,
			centerMode: false,
			infinite: false,
			responsive: [
				{
					breakpoint: 1025,
					settings: {
						slidesToShow: 2.33,
						arrows: false,
						dots: false
					}
				},{
					breakpoint: 992,
					settings: {
						slidesToShow: 1.8,
						arrows: false,
						dots: false
					}
				},{
					breakpoint: 768,
					settings: {
						slidesToShow: 1.1,
						arrows: false,
						dots: false
					}
				}
			]
		})
	}
}
</script>
