<template>
	<section class="bg-gradient-to-b from-[#1B1B1B]/25 to-rich-black" id="faq">
		<div class="container-area pb-[3.4375rem] pt-[2.375rem] lg:pb-[10.125rem] lg:pt-[8rem]">
			<h3 class="mx-auto mb-6 w-fit font-bold uppercase text-sean-green">faq</h3>
			<h2 class="mb-10 text-center text-4xl font-bold leading-7">Dúvidas Frequentes</h2>
			<ul class="mx-auto mb-[3.375rem] w-full md:w-[50rem] px-6 lg:px-0">
				<li v-for="(ask, index) in questions" :key="index" class="grid grid-rows-[5rem,auto] border-b border-b-spanish-gray/10 last:border-none">
					<div class="grid grid-cols-[4.5rem,auto,4.5rem] items-stretch">
						<span class="grid place-content-center font-bold leading-3 text-white" v-text="`0${index + 1}.`"></span>
						<h3 class="grid items-center font-light leading-3" v-text="ask.question"></h3>
						<button @click="toggle(ask)" class="grid place-content-center">
							<svg v-if="ask.open" width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0 0.111084V1.88886H16V0.111084H0Z" fill="#fff" />
							</svg>
							<svg v-if="!ask.open" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7.11111 0V7.11111H0V8.88889H7.11111V16H8.88889V8.88889H16V7.11111H8.88889V0H7.11111Z" fill="#fff" />
							</svg>
						</button>
					</div>
					<div class="grid grid-cols-[4.5rem,auto,4.5rem] overflow-hidden transition-all ease-linear" :class="ask.open ? 'h-auto pb-8' : 'h-0'">
						<span></span>
						<span class="font-light leading-3 text-spanish-gray" v-text="ask.response"></span>
						<span></span>
					</div>
				</li>
			</ul>
			<div class="group relative mx-auto flex h-[8.5rem] max-w-[50rem] items-center px-6 lg:px-0">
				<div class="pointer-events-none absolute top-6 left-1/2 z-20 hidden h-[30px] w-[200px] -translate-x-1/2 -translate-y-1/2 bg-[#000] mix-blend-saturation group-hover:top-1/2 lg:block"></div>
				<Link label="Comprar" path=" https://private-consig.catalog.yampi.io/mentoria-private-consig-v1/p" :external="true" class="relative z-10 mx-auto flex h-[8.5rem] w-full max-w-[50rem] items-center justify-center gap-3 rounded bg-eerie-black font-bold text-sean-green group-hover:h-[7.9rem] group-hover:max-w-[49.5rem]">
					<template #prefix>
						<div class="relative top-[1.5px]">
							<svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M12.2883 9.47794L6.88932 18.8299C6.82445 18.9426 6.73525 19.0353 6.62989 19.0996C6.52453 19.1639 6.40637 19.1977 6.28625 19.1979C6.21965 19.2096 6.15182 19.2096 6.08522 19.1979C5.93126 19.1481 5.79672 19.0422 5.70304 18.8972C5.60935 18.7522 5.56192 18.5765 5.5683 18.3979V12.7979H1.79907C1.47652 12.7988 1.15972 12.7027 0.882002 12.5199C0.604284 12.3372 0.375905 12.0743 0.220884 11.7592C0.0658632 11.444 -0.0100698 11.0881 0.00107121 10.7289C0.0122122 10.3697 0.110015 10.0204 0.284194 9.71794L5.68317 0.365935C5.76717 0.221022 5.89096 0.110249 6.03608 0.0501371C6.1812 -0.00997498 6.33987 -0.0162054 6.48842 0.032375C6.63698 0.0809554 6.76746 0.181744 6.86041 0.319708C6.95336 0.457673 7.0038 0.625421 7.00419 0.797935V6.39794H10.7734C11.096 6.3971 11.4128 6.49312 11.6905 6.67592C11.9682 6.85872 12.1966 7.12153 12.3516 7.43672C12.5066 7.7519 12.5826 8.10781 12.5714 8.46701C12.5603 8.82621 12.4625 9.17543 12.2883 9.47794Z" fill="#00FFB9" />
							</svg>
						</div>
					</template>
				</Link>
				<div class="absolute bottom-0 left-0 right-0 mx-auto hidden h-1/2 w-[50rem] rounded-b-md border-4 border-sean-green lg:block"></div>
			</div>
		</div>
	</section>
</template>
<script>
import Link from "@/components/Link.vue"

export default {
	name: "FaqSection",
	components: {
		Link
	},
	data() {
		return {
			questions: [
				{
					open: true,
					question: "A mentoria é gravada?",
					response: "A mentoria não é gravada, é realizada em uma reunião em vídeo com o mentorado através da plataforma Google meet"
				},
				{
					open: false,
					question: "Posso gravar minha mentoria?",
					response: "Não é permitido gravar a mentoria, por uma questão de propriedade intelectual"
				},
				{
					open: false,
					question: "Meu sócio pode participar?",
					response: "Caso ele(s) realmente esteja(m) no quadro social da empresa sim. (comprovado via contrato social ou cartão CNPJ)"
				},
				{
					open: false,
					question: "Qual o tempo de duração da mentoria?",
					response: "1 encontro por vídeo chamada com duração de aproximadamente 5 horas, contato pessoal do mentor para networking e participação no grupo privateconsig"
				},
				{
					open: false,
					question: "Recebo algum material descritivo?",
					response: "Sim. Scripts, manual de quebra de objeção, cronograma de sala, campanha de metas, manual de segmentação de leads"
				}
			]
		}
	},
	methods: {
		toggle(ask) {
			if (ask.open === true) {
				this.questions.forEach((question) => (question.open = false))
			} else {
				this.questions.forEach((question) => (question.open = false))
				ask.open = !ask.open
			}
		}
	}
}
</script>
