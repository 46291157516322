import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import App from './App.vue'

import './styles/app.css'

const head = createHead()

createApp(App)
	.use(head)
	.mount('#app')
